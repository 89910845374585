// src/HomePage.js
import React from 'react';
import './HomePage.css'; // Assurez-vous de créer ce fichier

const HomePage = () => {
  const handleTifoProjectClick = () => {
    window.open('/TifoProject/index.html', '_blank');
  };

  const handleTramaV2Click = () => {
    window.open('/tramaV2/index2.html', '_blank');
  };

  return (
    <div className="homepage">
      <img src={process.env.PUBLIC_URL + '/assets/logo.png'} className="logo" alt="logo" />
      <h1>Tiforama</h1>
      <div className="shortcuts">
        <button onClick={handleTifoProjectClick}>Envoyez votre Tifo</button>
        <button onClick={handleTramaV2Click}>Testez votre Tifo</button>
      </div>
    </div>
  );
}

export default HomePage;
